import { Container, Row, Col } from "react-bootstrap";

const ArticleArchiveSkelecton = () => {
  return (
    <Container fluid className="skeleton-product-display">
      <Row>
        <Col xs={12} className="skeleton-product-col">
          {[1, 2, 3, 4].map((item) => (
            // <Col md={4} key={item}>
              <div className="skeleton-product-item">
                <div className="skeleton-image"></div>
                <div className="skeleton-title"></div>
              </div>
            // </Col>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleArchiveSkelecton;
