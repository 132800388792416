import React from 'react';
import { Container } from 'react-bootstrap';

const HomeSliderSkeleton = () => {
  return (
    <Container fluid className='px-0'>
      <div className="hero-slider-container skeleton-box px-0">
        <div className="d-flex flex-column hero-slider-info-wrapper" style={{ width: "50%" }}>
          <div className="skeleton-box" style={{ height: "40px", width: "100px", marginBottom: "20px" }}></div>
          <div className="hero-slider-info-block">
            <div className="article-meta-container">
              <div className="skeleton-box" style={{ height: "20px", width: "150px", marginBottom: "10px" }}></div>
              <div className="skeleton-box" style={{ height: "40px", width: "80%", marginBottom: "15px" }}></div>
              <div className="skeleton-box" style={{ height: "60px", width: "100%", marginBottom: "20px" }}></div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="skeleton-box" style={{ height: "40px", width: "150px" }}></div>
                <div className="d-flex gap-1">
                  <div className="skeleton-box" style={{ height: "30px", width: "30px", marginRight: "10px" }}></div>
                  <div className="skeleton-box" style={{ height: "30px", width: "30px" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-slider-image-container">
          <div className="hero-slider-img1 skeleton-box" style={{ height: "100%", width: "70%" }}></div>
          <div className="hero-slider-img2 skeleton-box" style={{ height: "100%", width: "30%" }}></div>
        </div>
      </div>
    </Container>
  );
};

export default HomeSliderSkeleton;
