export default {
  // baseUrl: "https://thenovatex.co.in/admin/api/",
  // blogImageURL: "https://thenovatex.co.in/admin/public/uploads/blog_images/",
  // productImageURL:
  //   "https://thenovatex.co.in/admin/public/uploads/product_image/",
  // popupImageURL: "https://thenovatex.co.in/admin/public/uploads/popup_image/",
  baseUrl: "https://thenovatex.co.in/admin/api/",
  // baseUrl: "https://dev.admin.nour-beauty.com/editorial/api/",
  blogImageURL:
    "https://noureditorial.s3.me-central-1.amazonaws.com/blog_images/",
  productImageURL:
    "https://noureditorial.s3.me-central-1.amazonaws.com/product_image/",
  popupImageURL:
    "https://noureditorial.s3.me-central-1.amazonaws.com/popup_image/",
  bgColor: "#F7F9FC",
  primaryColor: "#1B30A5",
  secondaryColor: "#7265E6",
  primaryFont: '"Proxima Nova", sans-serif, Helvetica, Arial',
  secondaryFont: "Didot, serif",
};
