import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Carousel as SingleCarousel,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import ShareIcons from "./global-components/share-icons";
import { useGetArticleDetails, useGetBlogComments } from "../service/index";
import config from "../config/config";
import calculateTimeAgo from "../utils/helper";
import { Helmet } from "react-helmet";

// components
import CommentModal from "./comment-modal";
import ArticleDetailSkeleton from "./article-detail-skeleton";
import ReadMore from "./ReadMore";
import CommentRepliesPopup from "./comment-replies-popup";
import { Comment } from "../utils/type";

const ArticleDetail = () => {
  const { slug } = useParams();

  const [articleId, setArticleId] = useState();
  const [articleDetail, setArticleDetail] = useState<any>();
  const [articleCredits, setArticleCredits] = useState([]);
  const [products, setProducts] = useState([]);
  const [prevArticle, setPrevArticle] = useState<any>();
  const [nextArticle, setNextArticle] = useState<any>();
  const [showCommentModal, setShowCommentModal] = useState(false);
  // const [replyToCommentId, setReplyToCommentId] = useState<number | null>(null);
  const [blogComments, setBlogComments] = useState([]);
  const [commentConversation, setCommentConversation] = useState<Comment>();
  const [showCommentRepliesPopup, setShowCommentRepliesPopup] = useState(false);

  const {
    data: articleData,
    isLoading,
    isFetching,
    refetch: articleRefetch,
  } = useGetArticleDetails(slug);
  const { data: blogCommentsData, refetch: blogCommentsRefetch } =
    useGetBlogComments(articleDetail?.id);

  // functions
  // const handleReply = (commentId: number) => {
  //   setReplyToCommentId(commentId);
  //   setShowCommentModal(true);
  // };

  const handleAddComment = () => {
    // setReplyToCommentId(null);
    setShowCommentModal(true);
  };

  const handleCheckComments = (comment: Comment) => {
    setCommentConversation(comment);
    setShowCommentRepliesPopup(true);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 678, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    if (slug) {
      articleRefetch();
    }
  }, [slug, articleRefetch]);

  useEffect(() => {
    if (articleData) {
      setArticleDetail(articleData.data);
      setPrevArticle(articleData.related_blogs[0]);
      setNextArticle(articleData.related_blogs[1]);

      const credits =
        articleData?.data[0]?.written_by?.length !== ""
          ? JSON.parse(articleData?.data?.written_by)
          : [];
      setArticleCredits(credits);

      const products = articleData.data.products_details
        ? JSON.parse(articleData.data.products_details)
        : [];
      setProducts(products);

      setArticleId(articleData.data.id);
    }
  }, [articleData]);

  useEffect(() => {
    if (articleId) {
      blogCommentsRefetch();
    }
  }, [articleId, blogCommentsRefetch]);

  useEffect(() => {
    if (blogCommentsData) {
      setBlogComments(blogCommentsData.data);
    }
  }, [blogCommentsData]);

  if (isLoading || isFetching) {
    return <ArticleDetailSkeleton />;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {articleDetail?.meta_title !== ""
            ? articleDetail?.meta_title
            : articleDetail?.title}
        </title>
        <meta name="description" content={articleDetail?.meta_description} />
        <meta name="keywords" content={articleDetail?.meta_keywords} />
      </Helmet>
      <CommentModal
        showCommentModal={showCommentModal}
        setShowCommentModal={setShowCommentModal}
        // replyToCommentId={replyToCommentId}
        articleId={articleId}
      />
      <CommentRepliesPopup
        showCommentRepliesPopup={showCommentRepliesPopup}
        setShowCommentRepliesPopup={setShowCommentRepliesPopup}
        comment={commentConversation}
      />
      <div className="article-detail-wrapper mt-sm-5">
        <Container>
          <Row className="article-detail justify-content-center">
            <Col md="8" sm="12" className="flex-wrap">
              <a href={`${process.env.PUBLIC_URL}/articles/${articleDetail?.blogscategories[0].slug}`}>
                <h6 className="article-cat text-uppercase">
                  {articleDetail?.blogscategories[0].name}
                </h6>
              </a>
              {articleDetail?.sub_title && (
                <h2 className="article-subtitle">{articleDetail?.sub_title}</h2>
              )}
              <h2 className="article-title">
                <div
                  dangerouslySetInnerHTML={{
                    __html: articleDetail?.title_article_html,
                  }}
                ></div>
              </h2>

              <hr className="one" />

              <p className="article-excerpt">
                {articleDetail?.shortdescription}
              </p>

              <div className="article-credit-wrapper">
                {articleCredits?.map((credits: any) => (
                  <span key={credits.id}>
                    <span className="credit-title">
                      {credits?.written_by_title}
                    </span>{" "}
                    <i>{credits?.written_by}</i>
                  </span>
                ))}
              </div>

              <div className="article-feature-image">
                {articleDetail?.selected_option === "image" ? (
                  <img
                    src={
                      config.blogImageURL + articleDetail?.feature_img_article
                    }
                    alt={articleDetail?.title}
                    width={"100%"}
                  />
                ) : (
                  <iframe
                    className={
                      articleDetail?.video_size === "Landscape"
                        ? "article-feature-video-landscape"
                        : "article-feature-video-vertical"
                    }
                    height="460"
                    width={"100%"}
                    src={`${articleDetail?.video_link}&autoplay=1&mute=1`}
                    title={articleDetail?.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                )}
              </div>

              <div
                id="article-content"
                className="article-content-wrapper"
                dangerouslySetInnerHTML={{
                  __html: articleDetail?.longdescription,
                }}
              />
            </Col>
            {/* <Col sm="4" className="article-sidebar flex-wrap">
            <div className="article-share-div">
              <p>SHARE THIS</p>
              <ShareIcons
                title={articleDetail?.title}
                media={articleDetail?.feature_img_home}
              />
            </div>
            <hr className="one" />
            <div className="brand-name">
              <h5>NOUR</h5>
              <p className="mb-0 siderbar-label">Keep Reading</p>
              <p className="cat-name text-uppercase">
                {articleDetail?.blogscategories[0].name}
              </p>
            </div>
          </Col> */}
          </Row>

          {products.length !== 0 && (
            <Row>
              <Col sm="12">
                <div className="article-product-main-wrapper">
                  <h2>
                    {articleDetail?.product_heading
                      ? articleDetail?.product_heading
                      : "SHOP MY BAG"}
                  </h2>
                  {/* <hr className="heading-hr" /> */}
                  {products.map((product: any, index) => (
                    <Row className="product-row-wrapper" key={index}>
                      <Col sm="4" className="align-self-end">
                        <div className="numbers-container">
                          <span className="number-one">{index + 1}</span>
                          <span className="number-devider">/</span>
                          <span className="number-two">{products.length}</span>
                        </div>
                        <div className="product-description">
                          {product.product_shortdescription}
                        </div>
                        <div className="product-brand-name">
                          {product?.brand_url ? (
                            <a href={product.brand_url} target="_blank">
                              {product.product_category}
                            </a>
                          ) : (
                            product?.product_category
                          )}
                        </div>
                        <div className="product-name">
                          {product.product_title}
                        </div>
                        <div className="price-product">
                          AED
                          <span className="main-price">
                            {product.product_price}
                          </span>
                        </div>
                        <a className="shop-now-btn" href={product.product_url} target="_blank">
                          Shop Now
                        </a>
                        <hr
                          className={`w-100 d-md-none ${index + 1 === products.length ? "d-none" : ""
                            }`}
                        />
                      </Col>
                      <Col
                        xs="12"
                        sm="8"
                        className="product-media mt-3 d-flex align-items-center flex-column"
                      >
                        {product?.product_image.length === 1 ? (
                          <a href={product.product_url} className="w-sm-50" target="_blank">
                            <img
                              src={
                                config.productImageURL +
                                product?.product_image[0]
                              }
                              alt={product.product_title}
                              width={"100%"}
                            />
                          </a>
                        ) : (
                          <SingleCarousel className="w-sm-50">
                            {product?.product_image?.map(
                              (image: any, index: number) => (
                                <SingleCarousel.Item key={index}>
                                  <a href={product.product_url} target="_blank">
                                    <img
                                      src={config.productImageURL + image}
                                      alt={product.product_title}
                                      width={"100%"}
                                    />
                                  </a>
                                </SingleCarousel.Item>
                              )
                            )}
                          </SingleCarousel>
                        )}
                      </Col>
                      <hr className="mt-3 mb-0 w-100 d-none d-md-block" />
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col className="article-share-btn-footer">
              <div className="article-share-heading">SHARE THIS</div>
              <ShareIcons
                title={articleDetail?.title}
                media={articleDetail?.feature_img_home}
              />
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <hr className="two" />
        </Container>
        <Container>
          <Row className="comment-section my-5">
            <Col>
              <div className="comment-heading-container">
                <h3 className="">REAL PEOPLE, REAL REVIEWS</h3>
                {blogComments && blogComments.length > 0 ? (
                  <button
                    className="mt-4 add-comment-btn"
                    onClick={handleAddComment}
                  >
                    Add Comment
                  </button>
                ) : null}
              </div>
              {blogComments && blogComments.length > 0 ? (
                blogComments.map((comment: any) => (
                  <div key={comment.id} className="comment-item mt-4">
                    <div className="d-flex align-items-center mb-2">
                      <div className="initial-circle me-2">
                        {comment.name.charAt(0).toUpperCase()}
                      </div>
                      <div>
                        <h5 className="mb-0 text-capitalize">{comment.name}</h5>
                        <small>{calculateTimeAgo(comment.created_at)}</small>
                      </div>
                    </div>
                    <p className="text-capitalize">{comment.message}</p>

                    {comment.replies &&
                      comment.replies.map((reply: any) => (
                        <div key={reply.id} className="reply-item ml-4 mt-2">
                          <div className="d-flex align-items-center mb-2">
                            <div className="initial-circle me-2">
                              {reply.name.charAt(0).toUpperCase()}
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <h6 className="mb-0 text-capitalize me-2">
                                  {reply.name}
                                </h6>
                                <div className="tick-image">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/imgs/other/verify-tick.png`}
                                    alt="verify tick"
                                  />
                                </div>
                              </div>
                              <small>
                                {calculateTimeAgo(reply.created_at)}
                              </small>
                            </div>
                          </div>
                          <p className="text-capitalize">{reply.message}</p>
                        </div>
                      ))}
                  </div>
                ))
              ) : (
                <p>Be the first one to comment!</p>
              )}
              {blogComments.length === 0 ? (
                <button
                  className="mt-4 add-comment-btn"
                  onClick={handleAddComment}
                >
                  Add Comment
                </button>
              ) : null}
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <hr className="two pb-2" />
        </Container>
        <Container className="mt-5 mb-5">
          <h3 className="related-title">Related</h3>
          <Row className="next-prev-article">
            {prevArticle && (
              <Col xs={6}>
                <Link
                  to={`/article-detail/${prevArticle.blogscategories[0].slug}/${prevArticle.slug}`}
                  state={{ id: prevArticle.id }}
                  className="next-prev-article-link"
                >
                  {prevArticle?.feature_img_home === "" ? (
                    <iframe
                      src={`${prevArticle?.feature_video_url}?autoplay=1&mute=1&controls=0`}
                      title={prevArticle?.title}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                  ) : (
                    <img
                      src={config.blogImageURL + prevArticle.feature_img_home}
                      width="40%"
                      alt={prevArticle.title}
                    />
                  )}
                  <div className="meta-data">
                    <p className="mb-2">
                      <i>Keep Reading</i> {prevArticle.blogscategories[0].name}
                    </p>
                    <h3>{prevArticle.title}</h3>
                  </div>
                </Link>
              </Col>
            )}

            {nextArticle && (
              <Col xs={6}>
                <Link
                  to={`/article-detail/${nextArticle.blogscategories[0].slug}/${nextArticle.slug}`}
                  state={{ id: nextArticle.id }}
                  className="next-prev-article-link"
                >
                  {nextArticle?.feature_img_home === "" ? (
                    <iframe
                      src={`${nextArticle?.feature_video_url}?autoplay=1&mute=1&controls=0`}
                      title={nextArticle?.title}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                    ></iframe>
                  ) : (
                    <img
                      src={config.blogImageURL + nextArticle.feature_img_home}
                      width="40%"
                      alt={nextArticle.title}
                    />
                  )}
                  <div className="meta-data">
                    <p className="mb-2">
                      <i>Keep Reading</i> {nextArticle?.blogscategories[0].name}
                    </p>
                    <h3>{nextArticle?.title}</h3>
                  </div>
                </Link>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ArticleDetail;
